body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}

.login-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #d8eaff;
    flex-direction: column;
}

.auth-form {
    width: 300px;
    padding: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.auth-form label {
    font-size: 16px;
    margin-bottom: 5px;
    color: #333;
}

.auth-form input {
    font-size: 16px;
    padding: 10px;
    width: 100%;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.auth-form button {
    font-size: 16px;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.auth-form button:hover {
    background-color: #45a049;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.logo-container img {
    width: 150px;
    height: auto;
}