.about-container {
    margin-top: 50px;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    filter: brightness(65%);
    color: white;
    text-align: center;
    padding: 50px;
    font-family: 'Arial', sans-serif;
    line-height: 1.8;
}

.content {
    background: rgba(0, 0, 0, 0.5);
    padding: 30px;
    border-radius: 10px;
    display: inline-block;
    max-width: 800px;
    margin: auto;
}

.about-container h1, h2, h3 {
    margin: 20px 0;
    font-weight: bold;
}

.about-container ul {
    list-style: none;
    padding: 0;
}

.about-container ul li {
    margin: 10px 0;
    font-size: 18px;
}

.about-container p {
    font-size: 18px;
    margin: 20px 0;
}