.topNewPostPage{
    height: 100%;
    overflow-y: scroll;
}

.input-area{
    h2{
        text-align: center;
    }

    .input-area
    {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 5px;
        margin-bottom: 5px;
        .title{
            width: 30%;
            margin-top: 10px;
            margin-bottom: 10px;
        }
        .input-place {
            width: 70%;
            margin-top: 5px;
            margin-bottom: 5px;
            margin-right: 10px;
        }
        .scroll-ho{
            overflow-x: scroll;
            display: flex;
            width: 70%;
            justify-content: left;
        }
        .checkbox-item
        {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-left: 20px;
            label{
                margin-left: 10px;
            }
        }

    }

}

.scrollbar-vo::-webkit-scrollbar {
    width: 8px; /* 设置滚动条高度（横向滚动条） */
}
.scrollbar-ho::-webkit-scrollbar {
    height: 5px; /* 设置滚动条高度（横向滚动条） */
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #888; /* 滚动条颜色 */
    border-radius: 4px; /* 滚动条圆角 */
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; /* 悬浮时的滚动条颜色 */
}

.scrollbar::-webkit-scrollbar-track {
    background: transparent; /* 滚动条轨道透明 */
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none; /* 完全隐藏滚动条 */
}

/* 当鼠标悬浮时显示滚动条 */
.scrollbar:hover::-webkit-scrollbar {
    display: block;
}
