.topNavigation {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #333;
    color: white;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.topNavigation ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    flex: 1;
}

.topNavigation ul li:first-child {
    margin-right: 20px;
}

.topNavigation ul li img {
    max-height: 50px;
    object-fit: contain;
}

.topNavigation ul li a {
    text-decoration: none;
    color: white;
    padding: 10px 15px;
    transition: background-color 0.3s ease;
}

.topNavigation ul li a:hover {
    background-color: #575757;
    border-radius: 5px;
}

.topNavigation ul li a.active {
    background-color: #007bff;
    border-radius: 5px;
}

.topNavigation ul li {
    flex-grow: 1;
    text-align: center;
}