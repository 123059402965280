
body, html {
    height: 100%;
    margin: 0;
    font-family: Arial, sans-serif;
}
.topContainer {
    display: flex;
    height:  calc(100vh - 110px);
}
.container {
    display: flex;
    height: calc(100vh - 110px);
    margin-top: 110px;
}

.left {
    flex: 0.3;
    min-width: 200px;
    background-color: #f0f0f0;
    padding: 10px;
}

.right {
    flex: 0.7;
    background-color: #e0e0e0;
    padding: 20px;
    overflow-y: scroll;
}

.scrollable-container {
    width: 100%;
    height: 90%;
    overflow-y: scroll;
    overflow-x: hidden;
    scrollbar-gutter: stable;
    background-color: rgba(33, 55, 45, 0.04);
}

.detailContainer{
    background-color: #d8eaff;
    display: flex;
    flex-direction: column;
    height: 100px;
    width: 100%;
    margin-top: 60px;
}

.select-tag{
    overflow-y: hidden;
    overflow-x: auto;
    position: fixed;
    top:60px;
    width: 100%;
    white-space: nowrap;
    background-color: rgba(36, 83, 126, 0.46);
    height: 50px;
    ul {
        margin: 3px;
        height: 44px;
        list-style-type: none;
        display: inline-flex;
        background-color: transparent;
    }

    li {
        height: 100%;
        margin-right:5px;
        margin-left: 5px;
        float: left;
    }
    li a {
        height: 100%;
        display: block;
        color: #000000;
        text-align: center;
        padding: 11px 16px;
        background: rgba(255, 255, 255, 0.78);
        text-decoration: none;
        transition: background-color 0.3s ease;
        border-radius: 5px;

    }
    .active{
        background-color: #117a8b;
        color: white;
    }

     ul li a:hover {
         color: #ffffff;
        background-color: rgb(6, 78, 104);
        border-radius: 5px;
    }

     ul li {
        flex-grow: 1;
        text-align: center;
    }
}

.scrollbar-ho::-webkit-scrollbar {
    height: 8px;
}

.scrollbar-vo::-webkit-scrollbar {
    width: 8px;
}

.scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.scrollbar::-webkit-scrollbar-track {
    background: transparent;
}

.scrollbar-hidden::-webkit-scrollbar {
    display: none;
}


.scrollbar:hover::-webkit-scrollbar {
    display: block;
}
.SelectBar{
    p{
        white-space: normal;
    }
    a{
        color: #3c3b3b;
        display: block;
        padding: 14px 16px;
    }
    a:hover {
        text-decoration: none;
        background-color: #0c5460;
        color: white;
    }
    .upper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 10px;

    }
}
.Select{
    a{
    background: #117a8b;
    color:white;
    }
}

.price-select{
    height: 10%;
    flex-direction: column;
    display: flex;
    .firstLine{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        height: auto;
        padding: 10px;
        input{
            width: 35%;
            height: auto;
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 5px;
        }
        button{
            height: auto;
            background: #87acb3;
            color: #33343c;
            border: none;
            border-radius: 5px;
            width: 20%;
        }
    }
}
